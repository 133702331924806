import { IonItem, IonList, IonThumbnail } from "@ionic/react";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { AdventuresContext } from "../logic/adventures";
import { getChampionById, getTroopById } from "../logic/encounteres";
import {
  getVariableById,
  variableBelongsToAdventure,
} from "../logic/variables";
import { getLanguageKey } from "../utils";

const Cost = ({ costs, color }) => {
  const { t, i18n } = useTranslation();
  const { currentAdventureId } = useContext(AdventuresContext);

  const language = getLanguageKey(i18n.language);

  return (
    <div>
      <h3>{t("requirements")}</h3>
      <IonList className="ion-no-padding">
        {costs.map(
          (cost, index) =>
            cost &&
            variableBelongsToAdventure(currentAdventureId, cost) && (
              <div key={index}>
                <IonItem color={color}>
                  <IonThumbnail slot="start">
                    <img
                      alt={getVariableById(cost.variable._ref).label[language]}
                      src={`assets/icon/${
                        getVariableById(cost.variable._ref).img
                      }`}
                    />
                  </IonThumbnail>
                  <label className="no-screen-mobile">
                    {getVariableById(cost.variable._ref).label[language]} :{" "}
                    {"  "}
                  </label>
                  <strong className="ion-float-left ion-margin-start">
                    {cost.label[language]}

                    {cost.encounter &&
                      (cost.encounter === "troop"
                        ? getTroopById(cost.encounterType).label[language]
                        : getChampionById(cost.encounterType).label[language])}
                  </strong>
                </IonItem>
              </div>
            )
        )}
      </IonList>
    </div>
  );
};

export default Cost;

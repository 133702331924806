import { IonAvatar } from "@ionic/react";
import { useContext } from "react";

import { PlayersContext } from "../logic/players";
import "./CurrentPlayer.css";

const CurrentPlayer = () => {
  const { currentPlayer } = useContext(PlayersContext);
  const { players } = useContext(PlayersContext);
  const defaultDragons = [
    { color: "red", name: "Flare" },
    { color: "green", name: "Turogh " },
    { color: "white", name: "Esaphor " },
    { color: "blue", name: "Quja " },
  ];

  return (
    <div>
      {players.map((player, index) => (
        <IonAvatar
          key={index}
          className={`ion-align-self-center playerAvatar nextPlayers ${
            player === currentPlayer && "currentPlayer"
          }`}
        >
          <img
            alt={player}
            src={"/assets/img/dragon-cards-background/" + player + ".jpg"}
          />
        </IonAvatar>
      ))}
    </div>
  );
};

export default CurrentPlayer;

import {
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonTitle,
  IonToggle,
  IonToolbar,
} from "@ionic/react";

import {
  bookOutline,
  home,
  languageOutline,
  listOutline,
  search,
  timerOutline,
  moon,
} from "ionicons/icons";

import { useHistory, useLocation } from "react-router-dom";
import "./Menu.css";
import { useTranslation } from "react-i18next";
import { AdventuresContext } from "../logic/adventures";
import { useContext } from "react";

const Menu = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const history = useHistory();
  const { currentAdventureId } = useContext(AdventuresContext);

  const toggleDarkModeHandler = () => document.body.classList.toggle("dark");

  return (
    <IonMenu contentId="main" type="overlay">
      <IonHeader>
        <IonToolbar className="dragon-skin-background">
          <IonTitle color="light"></IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList id="inbox-list">
          <IonListHeader>Menu</IonListHeader>

          <IonMenuToggle autoHide={false}>
            <IonItem
              className={location.pathname === "/home" ? "selected" : ""}
              routerLink="/home"
              routerDirection="none"
              lines="none"
              detail={false}
            >
              <IonIcon slot="start" icon={home}></IonIcon>
              <IonLabel>Homepage</IonLabel>{" "}
            </IonItem>
            {currentAdventureId && (
              <IonItem
                className={
                  location.pathname === "/search-story" ? "selected" : ""
                }
                routerLink="/search-story"
                routerDirection="none"
                lines="none"
                detail={false}
              >
                <IonIcon slot="start" icon={search}></IonIcon>
                <IonLabel>{t("search-story")}</IonLabel>
              </IonItem>
            )}
            <IonItem
              className={location.pathname === "/history" ? "selected" : ""}
              routerLink="/history"
              routerDirection="none"
              lines="none"
              detail={false}
            >
              <IonIcon slot="start" icon={timerOutline}></IonIcon>
              <IonLabel>{t("history")}</IonLabel>
            </IonItem>
            <IonItem
              className={location.pathname === "/language" ? "selected" : ""}
              routerLink="/language"
              routerDirection="none"
              lines="none"
              detail={false}
            >
              <IonIcon slot="start" icon={languageOutline}></IonIcon>
              <IonLabel>{t("language")}</IonLabel>
            </IonItem>
            <IonItem className="ion-hide-md-down" lines="full">
              <IonIcon slot="start" icon={moon}></IonIcon>
              <IonLabel>Dark Theme</IonLabel>
              <IonToggle
                id="themeToggle"
                slot="end"
                name="darkMode"
                onIonChange={() => toggleDarkModeHandler()}
              ></IonToggle>
            </IonItem>
          </IonMenuToggle>
          <IonListHeader>Staff Only</IonListHeader>
          <IonMenuToggle autoHide={false}>
            <IonItem
              className={location.pathname === "/storyList" ? "selected" : ""}
              routerLink="/storyList"
              routerDirection="none"
              lines="none"
              detail={false}
            >
              <IonIcon slot="start" icon={listOutline}></IonIcon>
              <IonLabel>{t("storyList")}</IonLabel>
            </IonItem>
            <IonItem
              className={location.pathname === "/storyBook" ? "selected" : ""}
              routerLink="/storyBook"
              routerDirection="none"
              lines="none"
              detail={false}
            >
              <IonIcon slot="start" icon={bookOutline}></IonIcon>
              <IonLabel>{t("storyBook")}</IonLabel>
            </IonItem>
            <IonItem
              className={location.pathname === "/eventList" ? "selected" : ""}
              routerLink="/eventList"
              routerDirection="none"
              lines="none"
              detail={false}
            >
              <IonIcon slot="start" icon={bookOutline}></IonIcon>
              <IonLabel>{t("eventList")}</IonLabel>
            </IonItem>
          </IonMenuToggle>
        </IonList>
      </IonContent>
    </IonMenu>
  );
};

export default Menu;

import { getData } from "./data";

const data = getData();

const getElementById = primaryElementId => data.elements.find(e => e._id === primaryElementId);

const getAlignmentById = aligneamentId => data.alignments.find(a => a._id === aligneamentId);

const getLevelById = levelId => data.levels.find(t => t._id === levelId);

const getLevelByLevelNumber = levelNumber => data.levels.find(l => l.level === levelNumber);

export {
    getElementById,
    getAlignmentById,
    getLevelById,
    getLevelByLevelNumber
}
import { IonItem, IonList, IonThumbnail } from "@ionic/react";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { AdventuresContext } from "../logic/adventures";
import { getChampionById, getTroopById } from "../logic/encounteres";
import {
  getVariableById,
  variableBelongsToAdventure,
} from "../logic/variables";
import { getLanguageKey } from "../utils";

const Result = ({ results, color }) => {
  const { t, i18n } = useTranslation();
  const { currentAdventureId } = useContext(AdventuresContext);

  const language = getLanguageKey(i18n.language);

  return (
    <div>
      <h3>{t("rewards")}</h3>
      <IonList className="ion-no-padding">
        {results.map(
          (result, index) =>
            result &&
            variableBelongsToAdventure(currentAdventureId, result) && (
              <IonItem color={color} key={index}>
                {getVariableById(result.variable._ref).img && (
                  <IonThumbnail slot="start">
                    <img
                      alt={
                        getVariableById(result.variable._ref).label[language]
                      }
                      src={`assets/icon/${
                        getVariableById(result.variable._ref).img
                      }`}
                    />
                  </IonThumbnail>
                )}
                <label className="no-screen-mobile">
                  {getVariableById(result.variable._ref).label[language]}:{" "}
                  {"  "}
                </label>
                <strong className="ion-float-left ion-margin-start">
                  {result.label[language]}
                  {result.encounter &&
                    (result.encounter === "troop"
                      ? getTroopById(result.encounterType).label[language]
                      : getChampionById(result.encounterType).label[language])}
                </strong>
              </IonItem>
            )
        )}
      </IonList>
    </div>
  );
};

export default Result;

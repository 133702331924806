import {
  IonButtons,
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonRippleEffect,
  IonGrid,
  IonCol,
  IonRow,
} from "@ionic/react";
import "./HomePage.css";
import { useTranslation } from "react-i18next";

const LanguagePage = () => {
  const { t, i18n } = useTranslation();

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar className="dragon-skin-background">
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Age of Dragons - {t("language")}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="body-background">
        <IonCard className="ion-padding large-content-with-background">
          <IonCardHeader>
            <h2 className="ion-text-center">{t("set-language")}</h2>
          </IonCardHeader>
          <IonCardContent>
            <IonGrid>
              <IonRow>
                <IonCol>
                  <IonButton
                    expand="full"
                    className="ion-activatable ripple-parent"
                    onClick={(e) => i18n.changeLanguage("en")}
                  >
                    {t("english")}
                    <IonRippleEffect></IonRippleEffect>
                  </IonButton>
                </IonCol>
                <IonCol>
                  <IonButton
                    expand="full"
                    className="ion-activatable ripple-parent"
                    onClick={(e) => i18n.changeLanguage("it")}
                  >
                    {t("italian")}
                    <IonRippleEffect></IonRippleEffect>
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonCardContent>
        </IonCard>
      </IonContent>
    </IonPage>
  );
};

export default LanguagePage;

import { IonButton, IonContent, IonGrid, IonModal, IonRow } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { useContext, useState } from "react";

import { emptyOrNull } from "../utils";
import { RoundsContext } from "../logic/rounds";
import { EventsContext, randomEvent } from "../logic/events";
import { useHistory } from "react-router";
import { PlayersContext } from "../logic/players";
import CurrentPlayer from "./CurrentPlayer";
import HistoryEvent from "./HistoryEvent";
import { AdventuresContext, getAdventureById } from "../logic/adventures";
import { StoriesContext } from "../logic/stories";

const RoundRisolution = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const { currentAdventureId } = useContext(AdventuresContext);
  const { setRound, currentRound } = useContext(RoundsContext);
  const { setCurrentEventId, addToEvents } = useContext(EventsContext);
  const {
    firstPlayer,
    players,
    reorderPlayers,
    setCurrentPlayer,
    currentPlayer,
    getIndexPlayer,
  } = useContext(PlayersContext);

  const { storiesCompleted } = useContext(StoriesContext);

  const goToNextRound = (round) => {
    if (emptyOrNull(round)) {
      setRound(1);
    } else {
      const newRound = round + 1;
      setRound(newRound);
      const event = randomEvent(currentAdventureId, newRound, storiesCompleted);
      setCurrentEventId(event?._id);
      addToEvents(event?._id);
    }

    if (round >= getAdventureById(currentAdventureId).turnsNumber) {
      console.log(round);
      history.push("/epilogue");
    } else {
      history.push("/eventPage");
    }
  };

  return (
    <div slot="end">
      <IonModal isOpen={showModal} backdropDismiss="false">
        <IonContent className="ion-padding">
          <img
            className="icon-phase"
            src="/assets/icon/SVG/End_Personal_Turn.svg"
            alt="Personal Turn"
          />
          <h3>Fase Generazione delle Risorse</h3>

          <p>
            Ogni drago genera le risorse dai luoghi che sono attualmente sotto
            la propria influenza.
          </p>
          <p>
            Se il luogo è collegato con una linea di comando continua ad una
            tana, è possibile collocare le risorse generate nella tana.
          </p>
          <p>
            Se il luogo è collegato con una linea di comando continua alla
            posizione del drago, è possibile collocare le risorse generate sulla
            plancia del giocatore.
          </p>
          <p>
            Se il luogo non è collegato a niente, lasciare le risorse generate
            nel medesimo luogo.
          </p>
          <p>
            Eseguire eventuali effetti ancora in corso dagli anni precedenti.
          </p>
          <hr />

          <HistoryEvent onlyRecurrent="true" />
        </IonContent>
        <IonButton color="light" onClick={() => setShowModal(false)}>
          {t("cancel")}
        </IonButton>

        <IonButton
          onClick={() => {
            reorderPlayers(players, firstPlayer);
            goToNextRound(currentRound);
            setShowModal(false);
          }}
        >
          {t("next-round")}
        </IonButton>
      </IonModal>
      <IonGrid>
        <IonRow className="ion-justify-content-center">
          <CurrentPlayer />

          {/* {getIndexPlayer(currentPlayer) === players.length - 1 ? ( */}
          <IonButton
            className="ion-align-self-center"
            color="primary"
            onClick={() => {
              setShowModal(true);
            }}
          >
            {t("next-phase")}
          </IonButton>
          {/*           ) : (
            <IonButton
              className="ion-align-self-center"
              color="primary"
              onClick={() => {
                setCurrentPlayer(players.at(getIndexPlayer(currentPlayer) + 1));
                history.push("/changeTurn");
              }}
            >
              {t("next-player")}
            </IonButton>
          )} */}
        </IonRow>
      </IonGrid>
    </div>
  );
};

export default RoundRisolution;

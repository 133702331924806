import { getData } from "./data";
import { getStoryById } from "./stories";

const data = getData();

const getTroopById = (troopId) => data.troops.find((t) => t._id === troopId);

const getChampionById = (championId) =>
  data.champions.find((c) => c._id === championId);

const getTroopByAlignment = (alignmentId, primaryElementId) =>
  data.troops.filter(
    (t) =>
      t.alignment?._ref === alignmentId &&
      (t.primaryElement?._ref === primaryElementId || !t.primaryElement)
  );

const getDifficultyByLevelNumber = (levelNumber) =>
  data.difficulties.find((d) => d.level === levelNumber);

const getChampionsByLevelNumber = (
  championLevel,
  currentStoryTileId,
  alignmentId,
  primaryElementId,
  troopTypeId,
  troopNumber
) =>
  data.champions.filter(
    (c) =>
      c.level === championLevel &&
      c.canAppearInDirectEncounters === true &&
      (getStoryById(c?.rootStory?._ref)?.tileId ===
        parseInt(currentStoryTileId) ||
        (c.alignment._ref === alignmentId &&
          (c.primaryElement?._ref === primaryElementId || !c.primaryElement) &&
          (c.troopType?._ref === troopTypeId || !troopNumber)))
  );

const findChampionDeafult = (currentStory) =>
  data.champions.find(
    (c) =>
      Array.isArray(c.rootStory) &&
      c.rootStory.some((s) => s._ref === currentStory._id)
  );

export {
  getTroopById,
  getChampionById,
  getTroopByAlignment,
  getDifficultyByLevelNumber,
  getChampionsByLevelNumber,
  findChampionDeafult,
};

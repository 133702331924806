import {
  IonAccordion,
  IonAccordionGroup,
  IonCardContent,
  IonCol,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonRow,
} from "@ionic/react";

import { useTranslation } from "react-i18next";
import { getLanguageKey } from "../utils";
import { PortableText } from "@portabletext/react";
import { EventsContext, getEventById } from "../logic/events";

import { refresh } from "ionicons/icons";
import { useContext } from "react";

const HistoryEvent = ({ onlyRecurrent }) => {
  const { t, i18n } = useTranslation();
  const { eventsIds } = useContext(EventsContext);

  const language = getLanguageKey(i18n.language);

  return (
    <div>
      <IonCardContent className="ion-no-padding">
        <IonAccordionGroup>
          {eventsIds.map((eventId, index) =>
            onlyRecurrent === "true" ? (
              getEventById(eventId)?.recurring && (
                <IonAccordion key={index}>
                  <IonItem slot="header">
                    <IonLabel>
                      [{t("year")}
                      {"  "}
                      {getEventById(eventId)?.roundNumber} ]{"  "}
                      {getEventById(eventId)?.title[language]}
                      {getEventById(eventId)?.recurring && (
                        <IonIcon
                          slot="end"
                          className="on-align-self-center ion-margin-start recurringIcon"
                          icon={refresh}
                        ></IonIcon>
                      )}
                    </IonLabel>
                  </IonItem>
                  <IonList slot="content">
                    <IonItem>
                      <IonRow>
                        <IonCol>
                          {Array.isArray(
                            getEventById(eventId)?.effect[language]
                          ) ? (
                            <PortableText
                              value={
                                getEventById(eventId)?.recurringEffect[language]
                              }
                            />
                          ) : (
                            <p>
                              {getEventById(eventId)?.recurringEffect[language]}
                            </p>
                          )}
                        </IonCol>
                      </IonRow>
                    </IonItem>
                  </IonList>
                </IonAccordion>
              )
            ) : (
              <IonAccordion key={index}>
                <IonItem slot="header">
                  <IonLabel>
                    [ {t("year")}
                    {"  "}
                    {getEventById(eventId)?.roundNumber} ]{"  "}
                    {getEventById(eventId)?.title[language]}
                    {getEventById(eventId)?.recurring && (
                      <IonIcon
                        slot="end"
                        className="on-align-self-center ion-margin-start recurringIcon"
                        icon={refresh}
                      ></IonIcon>
                    )}
                  </IonLabel>
                </IonItem>
                <IonList slot="content">
                  <IonItem>
                    <IonRow>
                      <IonCol>
                        {Array.isArray(
                          getEventById(eventId)?.effect[language]
                        ) ? (
                          <PortableText
                            value={getEventById(eventId)?.effect[language]}
                          />
                        ) : (
                          <p>{getEventById(eventId)?.effect[language]}</p>
                        )}
                      </IonCol>
                    </IonRow>
                  </IonItem>
                </IonList>
              </IonAccordion>
            )
          )}
        </IonAccordionGroup>
      </IonCardContent>
    </div>
  );
};

export default HistoryEvent;

import { toPlainText } from "@portabletext/react";
import { getLevelById } from "./logic/attributes";
import { getStoryById, getStoryResultsByStoryRoot } from "./logic/stories";
import * as XLSX from "xlsx/xlsx.mjs";
import { getVariableById, variableBelongsToAdventure } from "./logic/variables";
import { getAdventureById } from "./logic/adventures";

const emptyOrNull = (s) => s == null || s === "" || s === [] || s.length === 0;

const findCommonElements = (arr1, arr2) => {
  return arr1.some((item) => arr2.includes(item));
};

const getLanguageKey = (langaugeCode) => langaugeCode.split("-")[0];

const plainRichText = (richText) => {
  if (Array.isArray(richText)) {
    return toPlainText(richText);
  } else {
    return richText;
  }
};

const exportEventsToExcell = (events) => {
  const rows = events.map((row) => ({
    roundNumber: row.roundNumber,
    titleIT: row.title.it,
    titleEN: row.title.en,
    descriptionIT: plainRichText(row.description.it),
    descriptionEN: plainRichText(row.description.en),
    effectIT: plainRichText(row.effect.it),
    effectEN: plainRichText(row.effect.en),
    recurringEffectIT: plainRichText(row?.recurringEffect?.it),
    recurringEffectEN: plainRichText(row?.recurringEffect?.en),
    type: "event",
    updateAt: row._updatedAt,
  }));
  const wb = XLSX.utils.book_new(); // book
  const ws = XLSX.utils.json_to_sheet(rows); // sheet
  XLSX.utils.book_append_sheet(wb, ws, "Stories"); // Stories sheet name

  XLSX.utils.sheet_add_aoa(
    ws,
    [
      [
        "Round Number",
        "Title IT",
        "Title EN",
        "Description IT",
        "Description EN",
        "Effect IT",
        "Effect EN",
        "Recurring Effect IT",
        "Recurring Effect EN",
        "Type",
        "updateAt",
      ],
    ],
    {
      origin: "A1",
    }
  );

  XLSX.writeFile(wb, "Events.xlsx");
};

const exportStoriesToExcell = (stories) => {
  const rows = stories.map((row) => ({
    tileID: row.tileId,
    titleIT: row.title.it,
    titleEN: row.title.en,
    textIT: plainRichText(row.text.it),
    textEN: plainRichText(row.text.en),
    level: getLevelById(row.level._ref).level,
    type: "story",
  }));

  stories.map((s) =>
    getStoryResultsByStoryRoot(s._id).map((resultStory) =>
      rows.push({
        tileID: s.tileId,
        titleIT: resultStory.title.it,
        titleEN: resultStory.title.en,
        textIT: plainRichText(resultStory.text.it),
        textEN: plainRichText(resultStory.text.en),
        level: "",
        type: "result",
      })
    )
  );

  const wb = XLSX.utils.book_new(); // book
  const ws = XLSX.utils.json_to_sheet(rows); // sheet
  XLSX.utils.book_append_sheet(wb, ws, "Stories"); // Stories sheet name

  XLSX.utils.sheet_add_aoa(
    ws,
    [
      [
        "Tile ID",
        "Title IT",
        "Title EN",
        "Text IT",
        "Text EN",
        "Level",
        "type",
      ],
    ],
    {
      origin: "A1",
    }
  );

  XLSX.writeFile(wb, "Stories.xlsx");
};

const exportResultsToExcell = (results) => {
  const rows = results.map((row) => ({
    tileID: getStoryById(row.rootStory._ref).tileId,
    titleIT: row.title.it,
    titleEN: row.title.en,
    costs:
      row?.costs &&
      row?.costs
        .map((cost) => {
          if (
            variableBelongsToAdventure(
              getStoryById(row.rootStory._ref).adventure,
              cost
            )
          ) {
            return [
              getVariableById(cost?.variable?._ref)?.label.it +
                ":" +
                cost?.label.it,
            ];
          }
        })
        .toString(),
    rewards:
      row?.results &&
      row?.results
        .map((result) => {
          if (
            variableBelongsToAdventure(
              getStoryById(row.rootStory._ref).adventure,
              result
            )
          ) {
            return [
              getVariableById(result?.variable?._ref)?.label.it +
                ":" +
                result?.label.it,
            ];
          }
        })
        .toString(),
  }));

  const wb = XLSX.utils.book_new(); // book
  const ws = XLSX.utils.json_to_sheet(rows); // sheet
  XLSX.utils.book_append_sheet(wb, ws, "Results"); // Stories sheet name

  XLSX.utils.sheet_add_aoa(
    ws,
    [["Tile ID", "Title IT", "Title EN", "Costs", "Rewards"]],
    {
      origin: "A1",
    }
  );

  XLSX.writeFile(wb, "Results.xlsx");
};
export {
  emptyOrNull,
  getLanguageKey,
  plainRichText,
  exportStoriesToExcell,
  exportResultsToExcell,
  exportEventsToExcell,
  findCommonElements,
};

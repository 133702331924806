import {
  IonRow,
  IonCol,
  IonList,
  IonItem,
  IonLabel,
  IonAccordion,
  IonAccordionGroup,
  IonCard,
  IonCardContent,
  IonButton,
  IonIcon,
} from "@ionic/react";

import { PortableText } from "@portabletext/react";
import { useTranslation } from "react-i18next";
import { getAllEventsByAdventureId } from "../logic/events";
import { getLanguageKey } from "../utils";

import { exportEventsToExcell } from "../utils";
import { downloadOutline } from "ionicons/icons";

const EventList = (props) => {
  const { i18n } = useTranslation();
  const events = getAllEventsByAdventureId(props.adventureId);
  const language = getLanguageKey(i18n.language);

  return (
    <div>
      <IonButton color="primary" onClick={() => exportEventsToExcell(events)}>
        <IonIcon slot="start" icon={downloadOutline}></IonIcon>
        Export
      </IonButton>
      <IonAccordionGroup>
        {events.map((event, index) => (
          <IonAccordion key={index}>
            <IonItem slot="header">
              <IonLabel>
                [{event.roundNumber}] {event.title.it}
              </IonLabel>
            </IonItem>
            <IonList slot="content">
              <IonItem>
                <IonCard>
                  <IonCardContent>
                    <IonRow>
                      <IonCol>
                        {" "}
                        {Array.isArray(event.description?.it) ? (
                          <PortableText value={event.description[language]} />
                        ) : (
                          <p>{event.description[language]}</p>
                        )}
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol>
                        {" "}
                        {Array.isArray(event.effect?.it) ? (
                          <PortableText value={event.effect[language]} />
                        ) : (
                          <p>{event.effect[language]}</p>
                        )}
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol>
                        {" "}
                        {Array.isArray(event?.recurringEffect?.it) && (
                          <PortableText
                            value={event?.recurringEffect[language]}
                          />
                        )}
                      </IonCol>
                    </IonRow>
                  </IonCardContent>
                </IonCard>
              </IonItem>
            </IonList>
          </IonAccordion>
        ))}

        <strong>Total: {events.length}</strong>
      </IonAccordionGroup>
    </div>
  );
};

export default EventList;

import {
  IonRow,
  IonCol,
  IonList,
  IonItem,
  IonLabel,
  IonAccordion,
  IonAccordionGroup,
  IonGrid,
  IonCard,
  IonCardContent,
  IonButton,
  IonIcon,
} from "@ionic/react";

import {
  getAllStoriesByAdventureId,
  getStoryResultsByStoryRoot,
} from "../logic/stories";

import { PortableText } from "@portabletext/react";
import { exportStoriesToExcell } from "../utils";
import { downloadOutline } from "ionicons/icons";

const StoryBookList = (props) => {
  const stories = getAllStoriesByAdventureId(props.adventureId);

  return (
    <div>
      <IonButton color="primary" onClick={() => exportStoriesToExcell(stories)}>
        <IonIcon slot="start" icon={downloadOutline}></IonIcon>
        Export
      </IonButton>
      <IonAccordionGroup>
        {stories.map((story, index) => (
          <IonAccordion key={index}>
            <IonItem slot="header">
              <IonLabel>
                [{story.tileId}] {story.title.it}
              </IonLabel>
            </IonItem>
            <IonList slot="content">
              <IonItem>
                <IonCard>
                  <IonCardContent>
                    <IonRow>
                      <IonCol>
                        {" "}
                        {Array.isArray(story.text?.it) ? (
                          <PortableText value={story.text.it} />
                        ) : (
                          <p>{story.text.it}</p>
                        )}
                      </IonCol>
                    </IonRow>
                  </IonCardContent>
                </IonCard>
              </IonItem>

              {getStoryResultsByStoryRoot(story._id).map(
                (storyResult, indexB) => (
                  <IonItem key={indexB}>
                    <IonCard className="card-contrast">
                      <IonCardContent>
                        <IonGrid>
                          <IonRow>
                            <IonCol>{storyResult.title.it}</IonCol>
                            <IonCol>
                              {Array.isArray(storyResult.text?.it) ? (
                                <PortableText value={storyResult.text.it} />
                              ) : (
                                <p>{storyResult.text.it}</p>
                              )}
                            </IonCol>
                          </IonRow>
                        </IonGrid>
                      </IonCardContent>
                    </IonCard>
                  </IonItem>
                )
              )}
            </IonList>
          </IonAccordion>
        ))}

        <strong>Total: {stories.length}</strong>
      </IonAccordionGroup>
    </div>
  );
};

export default StoryBookList;

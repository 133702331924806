import { IonCard } from "@ionic/react";

import "./SearchStory.css";
import Cost from "../components/Cost";
import Result from "../components/Result";
import { useTranslation } from "react-i18next";
import { getLanguageKey } from "../utils";
import { PortableText } from "@portabletext/react";

const HistoryStory = ({ story }) => {
  const { t, i18n } = useTranslation();
  const {
    title,
    text,

    costs,
    results,
    alternativeCosts,
    alternativeResults,
    tileId,
  } = story;
  const language = getLanguageKey(i18n.language);

  return (
    <div>
      <IonCard className="ion-padding small-content-with-background">
        <h5>
          {t("number-story")} {tileId}
        </h5>
        <h2>{title[language]}</h2>

        <div className="ion-margin-bottom">
          {Array.isArray(text?.it) ? (
            <PortableText value={text[language]} />
          ) : (
            <p>{text[language]}</p>
          )}
        </div>
      </IonCard>
      {costs && (
        <IonCard className="ion-padding small-content-with-background">
          {costs && <Cost costs={costs} />}
          {results && <Result results={results} />}
        </IonCard>
      )}
      {alternativeCosts && (
        <IonCard color="medium" className="ion-padding StoryContent">
          <h3>{t("alternative-resolution")}</h3>
          {costs && <Cost color="medium" costs={alternativeCosts} />}
          {results && <Result color="medium" results={alternativeResults} />}
        </IonCard>
      )}
    </div>
  );
};

export default HistoryStory;

import { emptyOrNull } from "../utils";
import { getLevelById } from "./attributes";
import {
  getTroopByAlignment,
  getChampionsByLevelNumber,
  getDifficultyByLevelNumber,
  findChampionDeafult,
} from "./encounteres";
import { getStoryById } from "./stories";
import { getData } from "./data";

const data = getData();

// get a correct type of troops equal to the aligneament of the tile || human default
function generateRandomEncounter(currentStoryId) {
  const currentStory = getStoryById(currentStoryId);

  const level = getLevelById(currentStory?.level?._ref);
  const levelNumber = level.level;

  const difficulty = getDifficultyByLevelNumber(levelNumber);

  const championTileDeafult = findChampionDeafult(currentStory);
  console.log(championTileDeafult);

  // get the difficulty of the encounter
  const difficultySetting =
    difficulty.settings[Math.floor(Math.random() * difficulty.settings.length)];

  // get a correct type of the troop in the encounter
  const troops = getTroopByAlignment(
    currentStory.alignment?._ref,
    currentStory.primaryElement?._ref
  );

  const defaultTroop = data.troops.find((t) => t.slug.current === "humanEvil");

  var troop = troops[Math.floor(Math.random() * troops.length)] || defaultTroop;

  var troopNumber = difficultySetting.troopsNumber;
  var championLevel = difficultySetting.championLevel;

  if (!emptyOrNull(championLevel) && troopNumber === 0) {
    troopNumber = null;
  }

  var champion = null;

  if (!emptyOrNull(championLevel)) {
    // get a generic Champion equal to the difficulty level and tile aligneament
    const champions = getChampionsByLevelNumber(
      championLevel,
      currentStory.tileId,
      currentStory.alignment?._ref,
      currentStory.primaryElement?._ref,
      troop._id,
      troopNumber
    );

    champion = champions[Math.floor(Math.random() * champions.length)];
  }

  const encounterResults = currentStory.encounterResults;

  if (troopNumber === null && !champion && championLevel === 3) {
    troopNumber = 5;
  }

  if (!emptyOrNull(championTileDeafult)) {
    champion = championTileDeafult;
    troopNumber = null;
  }

  return { troopNumber, troop, champion, encounterResults };
}
export { generateRandomEncounter };

import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { getElementById } from "../logic/attributes";
import { getStoryById, StoriesContext } from "../logic/stories";
import { getLanguageKey } from "../utils";
import "./Story.css";

const StoryAttribute = (prop) => {
  const { i18n } = useTranslation();
  const { currentStoryId } = useContext(StoriesContext);

  const { primaryElement, tileId } = getStoryById(currentStoryId);

  const language = getLanguageKey(i18n.language);

  const currentElement = getElementById(primaryElement?._ref);

  return (
    tileId !== 1000 && (
      <div className="attribute-content ion-flex ion-justify-content-between">
        {currentElement && (
          <img
            className=" story-attribute-icon"
            alt={currentElement.label[language]}
            src={`assets/icon/${currentElement.img}`}
          />
        )}
        {tileId && (
          <img
            className=" story-attribute-icon"
            alt={tileId}
            src={`assets/img/story-numbers/${tileId}.webp`}
          />
        )}
      </div>
    )
  );
};

export default StoryAttribute;

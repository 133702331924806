import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonViewWillEnter,
} from "@ionic/react";
import { useContext, useState } from "react";
import HistoryStory from "../components/HistoryStory";
import { getStoryById, StoriesContext } from "../logic/stories";
import { useTranslation } from "react-i18next";
import { AdventuresContext } from "../logic/adventures";

const HistoryPage = () => {
  const [historyStories, setHistoryStories] = useState([]);
  const { t } = useTranslation();
  const { currentAdventureId } = useContext(AdventuresContext);
  const { storyHistory, clearHistory, clearStoryCompleted } =
    useContext(StoriesContext);

  // Called every time the page is visited
  useIonViewWillEnter(() => {
    setHistoryStories(storyHistory);
  });

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar className="dragon-skin-background">
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Age of Dragons - {t("history")}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="body-background">
        <IonButton
          className="ion-margin"
          onClick={() => {
            clearHistory();
            setHistoryStories(storyHistory);
            clearStoryCompleted();
          }}
        >
          {t("clear-history")}
        </IonButton>
        {currentAdventureId &&
          historyStories
            .slice(0)
            .reverse()
            .map((storyId, index) => (
              <HistoryStory key={index} story={getStoryById(storyId)} />
            ))}
      </IonContent>
    </IonPage>
  );
};

export default HistoryPage;

import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonProgressBar,
  IonTitle,
  IonCard,
  IonToolbar,
} from "@ionic/react";
import SearchStory from "../components/SearchStory";
import { RoundsContext } from "../logic/rounds";
import RoundRisolution from "../components/RoundRisolution";
import { AdventuresContext, getAdventureById } from "../logic/adventures";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { EventsContext, getEventById } from "../logic/events";
import { PortableText } from "@portabletext/react";
import { getLanguageKey } from "../utils";

const SearchPage = () => {
  const { t, i18n } = useTranslation();
  const language = getLanguageKey(i18n.language);
  const { eventId } = useContext(EventsContext);
  const event = getEventById(eventId);
  const { currentAdventureId } = useContext(AdventuresContext);
  const { currentRound } = useContext(RoundsContext);

  // Return blank page if needed arguments are null
  if (!currentAdventureId || !currentRound) return <IonPage></IonPage>;

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar className="dragon-skin-background">
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle slot="start">
            <span className="no-screen-mobile">
              {getAdventureById(currentAdventureId).name}
              {" - "}
            </span>
            {t("year")} {parseInt(currentRound)}
          </IonTitle>
          <RoundRisolution />
          <IonProgressBar value={parseInt(currentRound) / 12}></IonProgressBar>
        </IonToolbar>
      </IonHeader>
      <IonContent className="body-background">
        <IonCard className="ion-padding large-content-with-background">
          <img
            className="icon-phase"
            src="/assets/icon/SVG/Personal_Turn.svg"
            alt="Personal Turn"
          />
          <h3>Fase dei Draghi</h3>

          <p className="double-line-height">
            Azioni da svolgere in questo ordine:
          </p>
          <ul>
          <li>Mangiare</li>
            <li>Movimento</li>
            <li>Svolgimento Storia o Combattimento</li>
            <li>Scambio Risorse</li>
            <li>Mangiare e/o Avanzare di Livello</li>
            <li>Reclutamento</li>
          </ul>
        </IonCard>
        <SearchStory />
        <IonCard className="ion-padding large-content-with-background">
          <h6>{t("event-in-progress")}</h6>
          {Array.isArray(event?.effect?.it) ? (
            <PortableText value={event?.effect[language]} />
          ) : (
            <p> {event?.effect[language]}</p>
          )}
        </IonCard>
      </IonContent>
    </IonPage>
  );
};

export default SearchPage;

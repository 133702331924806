import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButton,
  IonCol,
  IonRow,
  IonCardContent,
  IonGrid,
} from "@ionic/react";
import "./HomePage.css";
import { useTranslation } from "react-i18next";

import { useState } from "react";
import { getAdventures } from "../logic/adventures";
import { getLanguageKey } from "../utils";
import StoryBookList from "../components/StoryBookList";

const StoryBookPage = () => {
  const { i18n } = useTranslation();
  const [adventureId, setSelectedAdventure] = useState(
    "9cdabb63-ed24-47e8-848e-e2369ce92f13"
  );
  const adventures = getAdventures();
  const language = getLanguageKey(i18n.language);
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar className="dragon-skin-background">
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Age of Dragons</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="body-background">
        <IonRow>
          {adventures.map((a, index) => (
            <IonCol key={index}>
              <IonButton
                className="adventure-thumbnail"
                expand="full"
                routerDirection="none"
                color={a._id === adventureId ? "primary" : "light"}
                onClick={() => setSelectedAdventure(a._id)}
              >
                {a.label[language]}
              </IonButton>
            </IonCol>
          ))}
        </IonRow>
        <IonCardContent>
          <IonGrid>
            <StoryBookList adventureId={adventureId} />
          </IonGrid>
        </IonCardContent>
      </IonContent>
    </IonPage>
  );
};

export default StoryBookPage;

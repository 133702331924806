import { getData } from "./data";

const data = getData();

const getVariableById = (variableId) =>
  data.variables.find((e) => e._id === variableId);

const variableBelongsToAdventure = (adventureId, variable) =>
  variable.showInAllAdventures
    ? true
    : variable.adventure.some((a) => a._ref === adventureId);

export { getVariableById, variableBelongsToAdventure };

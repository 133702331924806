import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import "./HomePage.css";
import DragonCards from "../components/DragonCards";
import Tabletop from "../components/Tabletop";

const BoardConfigurePage = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar className="dragon-skin-background">
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Age of Dragons</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="body-background">
        <Tabletop />
      </IonContent>
    </IonPage>
  );
};

export default BoardConfigurePage;

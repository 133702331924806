import {
  IonRow,
  IonCol,
  IonButton,
  IonCard,
  IonCardHeader,
  IonGrid,
  useIonToast,
  IonCardContent,
  IonList,
  IonItem,
  IonThumbnail,
  IonLabel,
} from "@ionic/react";
import "./DragonCards.css";
import { useTranslation } from "react-i18next";

import { useHistory } from "react-router";
import { useContext, useState } from "react";
import {
  getPlayerConfiguration,
  getRandomItem,
  getTileIdsToRemove,
  PlayersContext,
} from "../logic/players";
import { getOneStoryFromTileId, StoriesContext } from "../logic/stories";
import { getLevelById } from "../logic/attributes";

const Tabletop = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { setFirstPlayer, reorderPlayers, setCurrentPlayer } =
    useContext(PlayersContext);

  const [dragonsSelected, setDragons] = useState({ dragons: [] });
  const [present] = useIonToast();

  /**
   * Event handler for each buttons
   * @param {*} adventureId adventureId was clicked and passed to the local storage
   */

  return (
    <div>
      <IonCard className="ion-padding small-content-with-background">
        <h2 className="ion-text-center">{t("table-configuration")}</h2>

        <IonCardContent>
          <div>
            <div className="ion-margin-top">
              <h3>Realizzazione del tabellone</h3>
              <p>
                Il tabellone di Age of Dragons viene realizzato tramite il
                posizionamento delle tessere luogo da parte dei giocatori. Ogni
                partita avrà quindi un tabellone diverso dalla precedente. Il
                metodo con cui vengono piazzate le tessere è il seguente:
              </p>
              <div className="ion-justify-content-center ">
                <img alt="" src="assets/img/table-configuration/step-1.png" />
                <img alt="" src="assets/img/table-configuration/step-2.png" />
              </div>
              <p>
                Ogni giocatore piazza la propria tessera tana nella posizione
                indicata in figura, dopodiché colloca la fattoria in una casella
                adiacente alla sua tana.
              </p>
              <div className="ion-justify-content-center ">
                <img alt="" src="assets/img/table-configuration/step-3.png" />
              </div>
              <p>
                Prendete le tessere luogo di livello 1 e mescolatele. Ogni
                giocatore pesca 2 tessere luogo, ne sceglie una e la piazza sul
                tabellone negli slot di livello 1 adiacente ad una sua tessera
                luogo già presente.
              </p>
              <p>
                Ogni giocatore passa la tessera luogo avanzata al giocatore alla
                sua sinistra e prende la tessera avanzata al giocatore alla sua
                destra, dopodiché pesca un’altra tessera luogo e ripete il
                procedimento fintanto che le tessere luogo di livello 1 non sono
                finite.
              </p>
              <div className="ion-justify-content-center ">
                <img alt="" src="assets/img/table-configuration/step-4.png" />
                <img alt="" src="assets/img/table-configuration/step-5.png" />
              </div>
              <p>
                Una volta collocate tutte le tessere di livello 1 ripetete il
                procedimento con le tessere di livello 2 e, successivamente, con
                quelle di livello 3, fino ad aver completato il tabellone.
                Qualora due giocatori vogliono piazzare contemporaneamente la
                propria tessera sulla stessa casella, ha la priorità il
                giocatore la cui tessera presenta il numero maggiore.
              </p>
            </div>
          </div>
        </IonCardContent>
      </IonCard>
      <IonCard className="ion-padding small-content-with-background">
        {" "}
        <IonButton
          expand="block"
          size="large"
          onClick={() => {
            history.push("/intro");
          }}
        >
          {t("start")}
        </IonButton>
      </IonCard>
    </div>
  );
};

export default Tabletop;

import {
  IonAvatar,
  IonButton,
  IonChip,
  IonContent,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
} from "@ionic/react";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import { generateRandomEncounter } from "../logic/generateRandomEncounter";
import { StoriesContext } from "../logic/stories";
import { getLanguageKey } from "../utils";
import Result from "./Result";

const Encounter = () => {
  const { t, i18n } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const history = useHistory();
  const { addToStoriesCompleted, currentStoryId } = useContext(StoriesContext);

  const currentEncounter = generateRandomEncounter(currentStoryId);
  const language = getLanguageKey(i18n.language);

  const { troopNumber, troop, champion, encounterResults } = currentEncounter;

  return (
    <div>
      <IonModal isOpen={showModal} backdropDismiss="false">
        <IonContent className="ion-padding ion-text-center">
          <h3>Sconfiggi in combattimento </h3>
          <IonList>
            {troopNumber > 0 && (
              <IonItem className="ion-text-center">
                <IonAvatar>
                  <img
                    alt={troop.label[language]}
                    src={`assets/icon/${troop.img}`}
                  />
                </IonAvatar>
                <IonChip>
                  <strong>x {troopNumber}</strong>
                </IonChip>
                <p>{troop.special[language]}</p>
              </IonItem>
            )}

            {champion && (
              <IonItem className="ion-text-center">
                <img
                  src={`assets/img/champions-cards/${champion.img}`}
                  alt={champion.name}
                />
              </IonItem>
            )}
          </IonList>
          {encounterResults && <Result results={encounterResults} />}

          <IonButton
            expand="full"
            color="light"
            onClick={() => {
              setShowModal(false);
              history.push("/search-story");
            }}
          >
            Sei stato sconfitto
          </IonButton>
          <IonButton
            expand="full"
            onClick={() => {
              setShowModal(false);
              addToStoriesCompleted(currentStoryId);
              history.push("/search-story");
            }}
          >
            Hai vinto il combattimento
          </IonButton>
        </IonContent>
      </IonModal>
      <IonButton
        color="light"
        expand="full"
        onClick={() => {
          setShowModal(true);
        }}
      >
        {t("randomic-encounter")}
      </IonButton>
    </div>
  );
};

export default Encounter;

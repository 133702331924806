import { useEffect, useState, createContext } from "react";
import { getData } from "./data";

const data = getData();

/************************************************************
 * FUNCTIONS WITH NO STATE
 * Functions that don't access React state are found below.
 ************************************************************/

/**
 * get the list of the adventures
 * @returns list of the adventures
 */
const getAdventures = () => {
  const adventureList = data.adventures;

  return adventureList;
};

/**
 * get the list of stories by Adeventure ID
 * @returns list of stories
 */
const getAdventureById = (advetureId) => {
  const adventure = data.adventures.find((a) => a._id === advetureId);

  return adventure;
};

const getStoriesFromAdventure = (adventureId) => {
  return data.stories.filter((s) => s.adventure._ref === adventureId);
};

const isAdventure = (adventures, adventureId) => {
  return adventures.some((a) => a._ref === adventureId);
};

/************************************************************
 * REACT STATE
 * This module's React state is kept inside the context
 * defined below.
 ************************************************************/

/*
 * Custom context that holds the state for adventures
 */
const AdventuresContext = createContext({});

const AdventuresContextProvider = ({ children }) => {
  const [currentAdventureId, _setCurrentAdventureId] = useState(null);

  // Recover saved value on start
  useEffect(() => {
    try {
      const savedAdventureId = window.localStorage.getItem("adventure");
      _setCurrentAdventureId(savedAdventureId);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const setCurrentAdventureId = (adventureId) => {
    // Update state
    _setCurrentAdventureId(adventureId);

    // Also update localStorage
    try {
      window.localStorage.setItem("adventure", adventureId);
    } catch (error) {
      console.log(error);
    }
  };

  const clearAdventure = () => {
    setCurrentAdventureId(null);
  };

  const context = {
    currentAdventureId,
    setCurrentAdventureId,
    clearAdventure,
  };

  return (
    // the Provider gives access to the context to its children
    <AdventuresContext.Provider value={context}>
      {children}
    </AdventuresContext.Provider>
  );
};

export {
  getAdventures,
  getAdventureById,
  getStoriesFromAdventure,
  isAdventure,
  AdventuresContext,
  AdventuresContextProvider,
};

import { isAdventure } from "../logic/adventures";
import { emptyOrNull, findCommonElements } from "../utils";
import { getData } from "./data";
import { useEffect, useState, createContext } from "react";

const data = getData();

/************************************************************
 * FUNCTIONS WITH NO STATE
 * Functions that don't access React state are found below.
 ************************************************************/

/**
 * Recupera una storia dal suo ID.
 * @param {string} storyId - L'ID della storia da recuperare.
 * @returns {Object} La storia trovata o undefined se non esiste.
 */
const getStoryById = (storyId) => data.stories.find((s) => s._id === storyId);

/**
 * Verifica se gli eventi speciali sono presenti negli eventi correnti di una storia.
 * @param {Array} specialEvents - Gli eventi speciali da verificare.
 * @param {Array} events - Gli eventi della storia.
 * @returns {boolean} True se c'è un evento in comune, altrimenti false.
 */
const storyWithCurrentSpecialEventId = (specialEvents, events) => {
  if (specialEvents) {
    const specialEventsRef = specialEvents.map((sE) => sE._ref);
    return findCommonElements(events, specialEventsRef);
  } else {
    return false;
  }
};

/**
 * Verifica se una storia ha uno specifico evento speciale.
 * @param {string} storyId - L'ID della storia.
 * @param {string} eventId - L'ID dell'evento da cercare.
 * @returns {boolean} True se l'evento è presente nella storia, altrimenti false.
 */
const storyHasSpecialEventId = (storyId, eventId) => {
  const story = getStoryById(storyId); // Recupera la storia specifica usando storyId
  if (!story) return false; // Se la storia non esiste, ritorna false

  // Controlla se la storia ha eventi e se uno di questi eventi ha _ref uguale a eventId
  return story.event?.some((e) => e._ref === eventId) || false;
};

/**
 * Verifica se una storia è associata a un'avventura specifica.
 * @param {Object} story - La storia da controllare.
 * @param {string} adventureId - L'ID dell'avventura da cercare.
 * @returns {boolean} True se la storia appartiene all'avventura, altrimenti false.
 */
const storyHasAdventureId = (story, adventureId) => {
  if (story.type !== "story") {
    return false;
  }

  return story.adventure.some((a) => a._ref === adventureId);
};

/**
 * Recupera l'ID della storia associata a una tile specifica in un'avventura.
 * @param {string} adventureId - L'ID dell'avventura.
 * @param {number} tileId - L'ID della tile.
 * @param {Array} events - Gli eventi correnti.
 * @returns {string} L'ID della storia o undefined se non viene trovata.
 */
const getStoryIdFromTileId = (adventureId, tileId, events) => {
  const stories = data.stories.filter(
    (s) => storyHasAdventureId(s, adventureId) && s.tileId === parseInt(tileId)
  );

  const storyWithSpecialEvent = stories.find((s) =>
    storyWithCurrentSpecialEventId(s.event, events)
  );

  if (storyWithSpecialEvent) {
    return storyWithSpecialEvent?._id;
  } else {
    return stories.find((s) => emptyOrNull(s?.event))?._id;
  }
};

/**
 * Recupera una storia associata a una tile specifica.
 * @param {number} tileId - L'ID della tile.
 * @returns {Object} La storia trovata o undefined se non esiste.
 */
const getOneStoryFromTileId = (tileId) => {
  return data.stories.find((s) => s.tileId === parseInt(tileId));
};

/******** global functions for Stories */

/**
 * Recupera tutte le storie (non i risultati) e le ordina per tileId.
 * @returns {Array} Le storie ordinate.
 */
const getAllStories = () => {
  var storyList = data.stories.filter((s) => s.type === "story");
  storyList.sort((a, b) => (a.tileId > b.tileId ? 1 : -1));
  return storyList;
};

/**
 * Recupera tutti i risultati di una storia specifica (storia radice).
 * @param {string} storyRootId - L'ID della storia radice.
 * @returns {Array} I risultati associati alla storia radice.
 */
const getStoryResultsByStoryRoot = (storyRootId) =>
  data.stories.filter(
    (s) => s.rootStory?._ref === storyRootId && s.type === "result"
  );

/**
 * Recupera tutte le storie associate a un'avventura specifica.
 * @param {string} adventureId - L'ID dell'avventura.
 * @returns {Array} Le storie ordinate per tileId.
 */
const getAllStoriesByAdventureId = (adventureId) => {
  var storyList = data.stories.filter(
    (s) => s.type === "story" && isAdventure(s.adventure, adventureId)
  );
  storyList.sort((a, b) => (a.tileId > b.tileId ? 1 : -1));
  return storyList;
};

/**
 * Recupera tutti i risultati di storie associate a un'avventura specifica.
 * @param {string} adventureId - L'ID dell'avventura.
 * @returns {Array} I risultati delle storie ordinate per tileId.
 */
const getAllStoryResultsByAdventureId = (adventureId) => {
  var storyResults = data.stories.filter(
    (s) =>
      s.type === "result" &&
      isAdventure(getStoryById(s.rootStory._ref).adventure, adventureId)
  );
  storyResults.sort((a, b) =>
    getStoryById(a.rootStory._ref).tileId >
    getStoryById(b.rootStory._ref).tileId
      ? 1
      : -1
  );
  return storyResults;
};

/************************************************************
 * REACT STATE
 * This module's React state is kept inside the context
 * defined below.
 ************************************************************/

/*
 * Custom context that holds the state for stories
 */
const StoriesContext = createContext({});

/**
 * Provider per il contesto delle storie, gestisce lo stato delle storie.
 * @param {Object} children - I componenti figli.
 * @returns Il Provider che permette ai componenti figli di accedere al contesto.
 */
const StoriesContextProvider = ({ children }) => {
  const [currentStoryId, _setCurrentStoryId] = useState(null);
  const [storyHistory, _setStoryHistory] = useState([]);
  const [storiesCompleted, _setStoriesCompleted] = useState([]);

  // Recupera i valori salvati dallo storage locale all'inizio
  useEffect(() => {
    try {
      const savedCurrentStoryId = window.localStorage.getItem("currentStory");
      _setCurrentStoryId(savedCurrentStoryId);

      const savedStoryHistory = JSON.parse(
        window.localStorage.getItem("history")
      );
      _setStoryHistory(
        Array.isArray(savedStoryHistory) ? savedStoryHistory : []
      );

      const savedStoriesCompleted = JSON.parse(
        window.localStorage.getItem("storiesCompleted")
      );
      _setStoriesCompleted(
        Array.isArray(savedStoriesCompleted) ? savedStoriesCompleted : []
      );
    } catch (error) {
      console.log(error);
    }
  }, []);

  /**
   * Imposta la prossima storia da visualizzare.
   * @param {string} storyId - L'ID della storia successiva.
   */
  const setNextStory = (storyId) => {
    if (storyId == null) {
      console.error("STORY NOT FOUND");
      return;
    }
    const nextStory = getStoryById(storyId);

    if (nextStory.type === "story") {
      // Aggiorna lo stato
      _setCurrentStoryId(nextStory._id);

      // Aggiorna anche lo storage locale
      try {
        window.localStorage.setItem("currentStory", nextStory._id);
      } catch (error) {
        console.log(error);
      }
    }

    addToHistory(storyId);
  };

  /**
   * Resetta la storia corrente.
   */
  const clearCurrentStory = () => {
    _setCurrentStoryId(null);
    try {
      window.localStorage.setItem("currentStory", null);
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * Imposta l'introduzione per un'avventura.
   * @param {string} adventureId - L'ID dell'avventura.
   * @param {Array} events - Gli eventi correnti.
   */
  const setIntro = (adventureId, events) => {
    const intro = getStoryIdFromTileId(adventureId, 1, events);
    _setCurrentStoryId(intro);
    try {
      window.localStorage.setItem("currentStory", intro);
    } catch (error) {
      console.log(error);
    }
  };

  /******** functions for store History */

  /**
   * Imposta la cronologia delle storie.
   * @param {Array} newHistory - Nuova cronologia delle storie.
   */
  const setHistory = (newHistory) => {
    _setStoryHistory(newHistory);
    try {
      window.localStorage.setItem("history", JSON.stringify(newHistory));
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * Aggiunge una storia alla cronologia.
   * @param {string} storyId - L'ID della storia da aggiungere alla cronologia.
   */
  const addToHistory = (storyId) => {
    setHistory([...storyHistory, storyId]);
  };

  /**
   * Resetta la cronologia delle storie.
   */
  const clearHistory = () => {
    setHistory([]);
  };

  /******** functions for store Stories Completed */

  /**
   * Imposta la lista delle storie completate.
   * @param {Array} newStoriesCompleted - Nuova lista di storie completate.
   */
  const setStoriesCompleted = (newStoriesCompleted) => {
    _setStoriesCompleted(newStoriesCompleted);
    try {
      window.localStorage.setItem(
        "storiesCompleted",
        JSON.stringify(newStoriesCompleted)
      );
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * Aggiunge una storia alla lista delle storie completate.
   * @param {string} storyId - L'ID della storia completata.
   */
  const addToStoriesCompleted = (storyId) => {
    setStoriesCompleted([...storiesCompleted, storyId]);
  };

  /**
   * Aggiunge più storie alla lista delle storie completate.
   * @param {Array} storyIds - Gli ID delle storie completate.
   */
  const addManyToStoriesCompleted = (storyIds) => {
    setStoriesCompleted([...storiesCompleted, ...storyIds]);
  };

  /**
   * Resetta la lista delle storie completate.
   */
  const clearStoryCompleted = () => {
    setStoriesCompleted([]);
  };

  // Definizione del contesto
  const context = {
    setNextStory,
    clearCurrentStory,
    currentStoryId,
    setIntro,
    setHistory,
    storyHistory,
    addToHistory,
    clearHistory,
    setStoriesCompleted,
    storiesCompleted,
    addToStoriesCompleted,
    addManyToStoriesCompleted,
    clearStoryCompleted,
  };

  return (
    // Il Provider dà accesso al contesto ai componenti figli
    <StoriesContext.Provider value={context}>
      {children}
    </StoriesContext.Provider>
  );
};

/**
 * Controlla e gestisce la riproduzione dell'audio.
 * @param {string} command - Il comando da eseguire ("play" o "stop").
 */
const audioPlayer = (command) => {
  if (command == "play" && document.getElementById("audio")) {
    document.getElementById("audio").play();
  }

  if (command == "stop" && document.getElementById("audio")) {
    document.getElementById("audio").pause();
    document.getElementById("audio").currentTime = 0;
  }
};

export {
  getStoryById,
  storyWithCurrentSpecialEventId,
  storyHasSpecialEventId,
  storyHasAdventureId,
  getStoryIdFromTileId,
  getOneStoryFromTileId,
  getAllStories,
  getStoryResultsByStoryRoot,
  getAllStoriesByAdventureId,
  StoriesContext,
  StoriesContextProvider,
  getAllStoryResultsByAdventureId,
  audioPlayer,
};

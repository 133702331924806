import {
  IonRow,
  IonCol,
  IonButton,
  IonCard,
  IonCardHeader,
  IonGrid,
  useIonToast,
  IonCardContent,
} from "@ionic/react";
import "./DragonCards.css";
import { useTranslation } from "react-i18next";
import { IonRippleEffect } from "@ionic/react";
import { useHistory } from "react-router";
import { useContext, useEffect, useState } from "react";

import { EventsContext, randomEvent } from "../logic/events";
import {
  getPlayerConfiguration,
  getRandomItem,
  getTileIdsToRemove,
  PlayersContext,
} from "../logic/players";
import { AdventuresContext } from "../logic/adventures";
import { getOneStoryFromTileId, StoriesContext } from "../logic/stories";
import { getLevelById } from "../logic/attributes";
import { getAdventureById } from "../logic/adventures";

const DragonCards = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { setFirstPlayer, setNewPlayers, setCurrentPlayer } =
    useContext(PlayersContext);
  const { currentAdventureId } = useContext(AdventuresContext);
  const {
    storiesCompleted,
    clearStoryCompleted,
    addManyToStoriesCompleted,
    setIntro,
  } = useContext(StoriesContext);
  const { setCurrentEventId, addToEvents, eventsIds } =
    useContext(EventsContext);
  const [dragonsSelected, setDragons] = useState({ dragons: [] });
  const [present] = useIonToast();
  const [hasStoriesUpdated, setHasStoriesUpdated] = useState(false); // Nuovo stato per tracciare l'aggiornamento delle storie

  const defaultDragons = [
    { color: "red", name: "Flare" },
    { color: "green", name: "Turogh" },
    { color: "white", name: "Esaphor" },
    { color: "blue", name: "Quja" },
  ];

  const selectDragon = (dragon) => {
    setDragons(
      dragonsSelected.dragons.length === 0 ||
        !dragonsSelected.dragons.some((d) => d === dragon)
        ? { dragons: [...dragonsSelected.dragons, dragon] }
        : { dragons: removeDragon(dragon) }
    );
  };

  const removeDragon = (dragon) => {
    return dragonsSelected.dragons.filter(
      (data) => data.color !== dragon.color
    );
  };

  const inizializeEvent = () => {
    console.log("Inizializing event with completed stories:", storiesCompleted);
    const event = randomEvent(currentAdventureId, 1, storiesCompleted);
    if (event) {
      setCurrentEventId(event._id);
      addToEvents(event._id);
      setIntro(currentAdventureId, eventsIds);
    }
  };

  // useEffect per gestire l'aggiornamento delle storie completate
  useEffect(() => {
    if (hasStoriesUpdated) {
      // Se le storie sono state aggiornate, inizializza l'evento con quelle storie
      inizializeEvent();
    }
  }, [storiesCompleted, hasStoriesUpdated]); // Trigger su storiesCompleted o sul flag di aggiornamento

  const handleConfirmation = () => {
    if (dragonsSelected.dragons.length > 0) {
      clearStoryCompleted();

      // Verifica se ci sono storie da rimuovere
      const storiesToRemove = getPlayerConfiguration(
        dragonsSelected.dragons
      )?.tilesToBeRemoved;
      if (storiesToRemove) {
        const storyIdsToRemove = storiesToRemove.map((s) => s._ref);
        addManyToStoriesCompleted(storyIdsToRemove); // Aggiorna lo stato delle storie completate
        setHasStoriesUpdated(true); // Imposta il flag per indicare che le storie sono state aggiornate
      } else {
        // Se non ci sono storie da rimuovere, inizializza con l'array vuoto o con le storie attuali
        inizializeEvent();
      }

      // Imposta il primo giocatore e i nuovi giocatori
      const selectedFirstPlayer = getRandomItem(dragonsSelected.dragons);
      setFirstPlayer(selectedFirstPlayer);
      setNewPlayers(dragonsSelected.dragons, selectedFirstPlayer);
      setCurrentPlayer(selectedFirstPlayer);

      // Redireziona l'utente alla schermata corretta
      if (getAdventureById(currentAdventureId).starter) {
        history.push("/intro");
      } else {
        history.push("/boardConfiguration");
      }
    } else {
      present(t("select-one-player-at-least"), 2000);
    }
  };
  /**
   * Event handler for each buttons
   * @param {*} currentAdventureId currentAdventureId was clicked and passed to the local storage
   */

  return (
    <div>
      <IonCard className="ion-padding small-content-with-background">
        <h2 className="ion-text-center">{t("players-selection")}</h2>

        <IonCardContent>
          <IonGrid>
            <IonRow>
              {defaultDragons.map((dragon, index) => (
                <IonCol size="12" size-md="6" size-lg="6" key={index}>
                  <IonButton
                    className="dragon"
                    fill="clear"
                    onClick={() => {
                      selectDragon(dragon.color);
                    }}
                  >
                    <img
                      className={
                        dragonsSelected.dragons.length !== 0 &&
                        dragonsSelected.dragons.some(
                          (d) => d === dragon.color
                        ) &&
                        "dragonSelected"
                      }
                      src={
                        "/assets/img/dragon-cards-background/" +
                        dragon.color +
                        ".jpg"
                      }
                      alt={dragon.name}
                    />
                  </IonButton>
                </IonCol>
              ))}
            </IonRow>
          </IonGrid>
        </IonCardContent>
      </IonCard>
      {dragonsSelected.dragons.length > 1 &&
        dragonsSelected.dragons.length < 4 && (
          <IonCard className="ion-padding small-content-with-background">
            <h2 className="ion-text-center">{t("tile-to-be-removed")}</h2>

            <IonCardContent>
              <div>
                <div className="ion-align-items-center">
                  {dragonsSelected.dragons.length > 0 &&
                  dragonsSelected.dragons.length < 4
                    ? getTileIdsToRemove(
                        getPlayerConfiguration(dragonsSelected.dragons)
                          ?.tilesToBeRemoved
                      ).map((tileId, index) => (
                        <span key={index}>
                          {getLevelById(
                            getOneStoryFromTileId(tileId)?.level?._ref
                          )?.level === 1 ? (
                            <div className="hexagon-wrapper">
                              <div className="hex1 hexagon"></div>
                              <div className="hex-label white">{tileId}</div>
                            </div>
                          ) : getLevelById(
                              getOneStoryFromTileId(tileId)?.level?._ref
                            )?.level === 2 ? (
                            <div className="hexagon-wrapper">
                              <div className="hex2 hexagon"></div>
                              <div className="hex-label white">{tileId}</div>
                            </div>
                          ) : (
                            <div className="hexagon-wrapper">
                              <div className="hex3 hexagon"></div>
                              <div className="hex-label white">{tileId}</div>
                            </div>
                          )}
                        </span>
                      ))
                    : t("none")}
                </div>
              </div>
            </IonCardContent>
          </IonCard>
        )}
      <IonCard className="ion-padding small-content-with-background">
        <IonButton
          expand="block"
          size="large"
          onClick={handleConfirmation} // Chiamata separata alla funzione di conferma
        >
          {t("confirm")}
        </IonButton>
      </IonCard>
    </div>
  );
};

export default DragonCards;

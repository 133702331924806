import { useEffect, useState, createContext } from "react";

/************************************************************
 * REACT STATE
 * This module's React state is kept inside the context
 * defined below.
 ************************************************************/

/*
 * Custom context that holds the state for rounds
 */
const RoundsContext = createContext({});

const RoundsContextProvider = ({ children }) => {
  const [currentRound, _setCurrentRound] = useState(1);

  // Recover saved value on start
  useEffect(() => {
    try {
      const savedRound = window.localStorage.getItem("round");
      _setCurrentRound(savedRound != null ? parseInt(savedRound) : 1);
    } catch (error) {
      console.log(error);
    }
  }, []);

   const setRound = (newRound) => {
    // Update state
    _setCurrentRound(newRound);

    // Also update localStorage
    try {
      window.localStorage.setItem("round", newRound);
    } catch (error) {
      console.log(error);
    }
  };

  const context = {    
    setRound,
    currentRound,
  };

  return (
    // the Provider gives access to the context to its children
    <RoundsContext.Provider value={context}>{children}</RoundsContext.Provider>
  );
};

export { RoundsContext, RoundsContextProvider };

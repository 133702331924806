import {
  IonButton,
  IonCard,
  IonCol,
  IonGrid,
  IonIcon,
  IonRow,
  IonSearchbar,
  useIonToast,
} from "@ionic/react";

import { searchOutline } from "ionicons/icons";

import "./SearchStory.css";
import { getStoryIdFromTileId, StoriesContext } from "../logic/stories";
import { useTranslation } from "react-i18next";
import { useContext, useState } from "react";
import { useHistory } from "react-router";
import { AdventuresContext } from "../logic/adventures";
import { EventsContext } from "../logic/events";

const SearchStory = () => {
  const [searchText, setSearchText] = useState();
  const [present] = useIonToast();
  const { currentAdventureId } = useContext(AdventuresContext);
  const { eventsIds } = useContext(EventsContext);
  const { storiesCompleted, setNextStory } = useContext(StoriesContext);

  const { t } = useTranslation();
  const history = useHistory();

  const onSearch = (event) => {
    if (event.key === "Enter") {
      navigateToStory();
    }
  };

  const navigateToStory = () => {
    const storyId = getStoryIdFromTileId(
      currentAdventureId,
      parseInt(searchText),
      eventsIds
    );

    if (storiesCompleted.some((sId) => sId === storyId)) {
      present(t("story-already-completed"), 2000);
      return;
    } else {
      setNextStory(storyId);

      if (!storyId) {
        present(t("story-doesnt-exist"), 2000);
      } else {
        history.push(`/story/${storyId}`);
      }
    }
  };

  //const navigateToStoryDelayed = debounce(navigateToStory, 300);

  return (
    <IonCard className="ion-padding large-content-with-background">
      <h2>{t("search-story")}</h2>
      <IonGrid>
        <IonRow className="ion-align-items-center">
          <IonCol size="10">
            <IonSearchbar
              onIonChange={(e) => setSearchText(e.target.value)}
              debounce={0}
              onKeyPress={onSearch}
              value={searchText}
              showCancelButton="always"
              type="number"
              enterKeyHint="search"
              placeholder={t("number-story")}
            ></IonSearchbar>
          </IonCol>
          <IonCol size="2">
            <IonButton expand="block" size="large" onClick={navigateToStory}>
              <IonIcon icon={searchOutline}></IonIcon>
            </IonButton>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonCard>
  );
};

export default SearchStory;

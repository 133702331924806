import {
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonToolbar,
  IonProgressBar,
  IonTitle,
  IonAvatar,
  useIonViewDidEnter,
} from "@ionic/react";
import "./HomePage.css";
import { useTranslation } from "react-i18next";
import { RoundsContext } from "../logic/rounds";
import { AdventuresContext, getAdventureById } from "../logic/adventures";
import { useHistory } from "react-router";
import { PlayersContext } from "../logic/players";
import { useContext } from "react";

const ChangeTurnPage = () => {
  const history = useHistory();
  const { currentRound } = useContext(RoundsContext);

  const defaultDragons = [
    { color: "red", name: "Flare" },
    { color: "green", name: "Turogh " },
    { color: "white", name: "Esaphor " },
    { color: "blue", name: "Quja " },
  ];

  const { t } = useTranslation();
  const { currentAdventureId } = useContext(AdventuresContext);
  const { currentPlayer } = useContext(PlayersContext);

  // Navigate away after 1.5 seconds of entering the page
  useIonViewDidEnter(() => {
    setTimeout(() => {
      history.push("search-story");
    }, 1500);
  });

  // Return blank page if needed arguments are null
  if (!currentAdventureId) return <IonPage></IonPage>;

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar className="dragon-skin-background">
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          {currentRound <
          getAdventureById(currentAdventureId).turnsNumber + 1 ? (
            <IonTitle>{getAdventureById(currentAdventureId).name}</IonTitle>
          ) : (
            <IonTitle>{t("changeTurn")}</IonTitle>
          )}
          <IonProgressBar
            value={
              parseInt(currentRound) /
              getAdventureById(currentAdventureId).turnsNumber
            }
          ></IonProgressBar>
        </IonToolbar>
      </IonHeader>
      <IonContent className="body-background-stone">
        <IonCard className="ion-padding small-content">
          <h2 class="first-player-title">
            {"Il primo giocatore è " +
              defaultDragons.find((dragon) => currentPlayer === dragon.color)
                .name}
          </h2>
          <img
            alt={currentPlayer}
            src={"/assets/img/dragon-wallpapers/" + currentPlayer + ".webp"}
          />

          <IonCardContent></IonCardContent>
        </IonCard>
      </IonContent>
    </IonPage>
  );
};

export default ChangeTurnPage;

import {
  IonRow,
  IonCol,
  IonButton,
  IonCard,
  IonCardContent,
  IonRippleEffect,
} from "@ionic/react";
import { AdventuresContext, getAdventures } from "../logic/adventures";
import { getLanguageKey } from "../utils";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { RoundsContext } from "../logic/rounds";
import "./AdventureList.css";
import { useContext } from "react";

const AdventureList = () => {
  const history = useHistory();
  const { i18n } = useTranslation();
  const { setCurrentAdventureId } = useContext(AdventuresContext);
  const { setRound } = useContext(RoundsContext);

  const language = getLanguageKey(i18n.language);

  /**
   * Event handler for each buttons
   * @param {*} adventureId adventureId was clicked and passed to the local storage
   */
  const searchAdventure = (adventureId) => {
    setCurrentAdventureId(adventureId);
    setRound(1);
    history.push("/gameConfiguration");
  };
  const adventures = getAdventures();

  return (
    <IonRow>
      {adventures.map(
        (adventure, index) =>
          adventure.published && (
            <IonCol size="12" size-md="6" size-lg="6" key={index}>
              <IonButton
                className="adventure-background"
                fill="clear"
                routerDirection="none"
                color="primary"
                onClick={() => {
                  searchAdventure(adventure._id);
                }}
              >
                <img
                  className="ripple-parent"
                  src={`assets/img/adventures-background/${adventure.img}`}
                  alt={adventure.label[language]}
                />
                <p>{adventure.label[language]}</p>
                <IonRippleEffect></IonRippleEffect>
              </IonButton>
            </IonCol>
          )
      )}
    </IonRow>
  );
};

export default AdventureList;

import { isAdventure } from "../logic/adventures";
import { getData } from "./data";
import { getStoryById, storyHasSpecialEventId } from "./stories";
import { useEffect, useState, createContext } from "react";
import { emptyOrNull } from "../utils";
import { isArray } from "lodash";

const data = getData();

/************************************************************
 * FUNCTIONS WITH NO STATE
 * Functions that don't access React state are found below.
 ************************************************************/

const getEventById = (eventId) => {
  const event = data.events.find((e) => e._id === eventId);
  return event;
};

const randomEvent = (currentAdventureId, currentRound, completedStories) => {
  console.log(completedStories);
  // Filtra gli eventi basati sul round e sull'avventura corrente
  const events = data.events.filter(
    (e) =>
      e.roundNumber === currentRound && // Filtro per il round attuale
      e.adventure.some((a) => a?._ref === currentAdventureId) && // Filtro per l'avventura attuale
      !completedStories.some((storyId) =>
        storyHasSpecialEventId(storyId, e._id)
      ) // Escludi eventi già usati
  );

  console.log(events); // Debug: controlla la lista di eventi filtrati

  // Se ci sono eventi disponibili, scegli un evento casuale
  if (events.length > 0) {
    const event = events[Math.floor(Math.random() * events.length)];
    return event;
  }

  // Se nessun evento è disponibile, ritorna null o gestisci il caso in modo diverso
  return null;
};

const getAllEventsByAdventureId = (adventureId) => {
  var eventList = data.events.filter((e) =>
    isAdventure(e.adventure, adventureId)
  );
  eventList.sort((a, b) => (a.roundNumber > b.roundNumber ? 1 : -1));
  return eventList;
};

/************************************************************
 * REACT STATE
 * This module's React state is kept inside the context
 * defined below.
 ************************************************************/

/*
 * Custom context that holds the state for events
 */
const EventsContext = createContext({});

const EventsContextProvider = ({ children }) => {
  const [eventId, _setEventId] = useState(null);
  const [eventsIds, _setEventsIds] = useState([]);

  // Recover saved values on start
  useEffect(() => {
    try {
      const savedCurrentEvent = window.localStorage.getItem("currentEvent");
      _setEventId(savedCurrentEvent);

      const savedEvents = JSON.parse(window.localStorage.getItem("events"));
      _setEventsIds(Array.isArray(savedEvents) ? savedEvents : []);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const setCurrentEventId = (eventId) => {
    // Update state
    _setEventId(eventId);

    // Also update localStorage
    try {
      window.localStorage.setItem("currentEvent", eventId);
    } catch (error) {
      console.log(error);
    }
  };

  const setEvents = (newEvents) => {
    // Update state
    _setEventsIds(newEvents);

    // Also update localStorage
    try {
      window.localStorage.setItem("events", JSON.stringify(newEvents));
    } catch (error) {
      console.log(error);
    }
  };

  const addToEvents = (eventId) => {
    setEvents([...eventsIds, eventId]);
  };

  const clearEvents = () => {
    setEvents([]);
  };

  const clearCurrentEventId = () => {
    setCurrentEventId(null);
  };

  const context = {
    setCurrentEventId,
    setEvents,
    addToEvents,
    eventsIds,
    eventId,
    clearEvents,
    clearCurrentEventId,
  };

  return (
    // the Provider gives access to the context to its children
    <EventsContext.Provider value={context}>{children}</EventsContext.Provider>
  );
};

export {
  getEventById,
  randomEvent,
  getAllEventsByAdventureId,
  EventsContext,
  EventsContextProvider,
};

import {
  IonButtons,
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonRippleEffect,
  IonGrid,
  IonCol,
  IonRow,
} from "@ionic/react";
import "./HomePage.css";
import { useTranslation } from "react-i18next";
import { AdventuresContext } from "../logic/adventures";
import { StoriesContext } from "../logic/stories";
import { EventsContext } from "../logic/events";
import { useHistory } from "react-router";
import { useContext } from "react";
import { PlayersContext } from "../logic/players";

const HomePage = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { currentAdventureId, clearAdventure } = useContext(AdventuresContext);
  const { clearEvents, clearCurrentEventId } = useContext(EventsContext);
  const { clearHistory, clearCurrentStory, clearStoryCompleted } =
    useContext(StoriesContext);
  const { clearPlayers } = useContext(PlayersContext);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar className="dragon-skin-background">
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Age of Dragons</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="body-home-background">
        <IonCard className="large-content">
          <div className="ion-padding">
            <img src="/assets/img/logo.png" alt="Age of Dragons logo" />
          </div>
        </IonCard>
        <IonCard className="ion-padding large-content">
          <IonCardContent>
            <IonGrid>
              <IonRow>
                <IonCol>
                  <IonButton
                    expand="full"
                    className="ion-activatable ripple-parent"
                    onClick={() => {
                      clearHistory();
                      clearAdventure();
                      clearCurrentStory();
                      clearEvents();
                      clearCurrentEventId();
                      clearStoryCompleted();
                      clearPlayers();
                    }}
                    href="/select-adventure"
                  >
                    {t("new-game")}
                    <IonRippleEffect></IonRippleEffect>
                  </IonButton>
                </IonCol>
              </IonRow>

              {currentAdventureId && (
                <IonRow>
                  {console.log(currentAdventureId)}
                  <IonCol>
                    <IonButton
                      expand="full"
                      className="ion-activatable ripple-parent"
                      onClick={() => history.push("/search-story")}
                      color="light"
                    >
                      {t("continue-game")}
                      <IonRippleEffect></IonRippleEffect>
                    </IonButton>
                  </IonCol>
                </IonRow>
              )}
              <IonRow>
                <IonCol>
                  <IonButton
                    expand="full"
                    className="ion-activatable ripple-parent"
                    onClick={() => history.push("/language")}
                    color="light"
                  >
                    {t("language")}
                    <IonRippleEffect></IonRippleEffect>
                  </IonButton>
                </IonCol>
                <IonCol>
                  <IonButton
                    expand="full"
                    className="ion-activatable ripple-parent"
                    onClick={() => history.push("/history")}
                    color="light"
                  >
                    {t("history")}
                    <IonRippleEffect></IonRippleEffect>
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonCardContent>
        </IonCard>
      </IonContent>
    </IonPage>
  );
};

export default HomePage;

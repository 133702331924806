import {
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonGrid,
  IonButton,
  IonCol,
  IonRow,
} from "@ionic/react";
import "./HomePage.css";
import { useTranslation } from "react-i18next";

import StoryList from "../components/StoryList";
import { useState } from "react";
import { getAdventures } from "../logic/adventures";
import { getLanguageKey } from "../utils";

const StoryListPage = () => {
  const { i18n } = useTranslation();
  const [adventureId, setSelectedAdventure] = useState(
    "9cdabb63-ed24-47e8-848e-e2369ce92f13"
  );
  const adventures = getAdventures();
  const language = getLanguageKey(i18n.language);
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar className="dragon-skin-background">
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Age of Dragons</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="body-background">
        <IonRow>
          {adventures.map((a, index) => (
            <IonCol key={index}>
              <IonButton
                className="adventure-thumbnail"
                expand="full"
                routerDirection="none"
                color={a._id === adventureId ? "primary" : "light"}
                onClick={() => setSelectedAdventure(a._id)}
              >
                {a.label[language]}
              </IonButton>
            </IonCol>
          ))}
        </IonRow>
        <IonCard className="ion-padding">
          <IonCardHeader>
            <h2 className="ion-text-center">Story List</h2>
          </IonCardHeader>
          <IonCardContent>
            <IonGrid>
              <StoryList adventureId={adventureId} />
            </IonGrid>
          </IonCardContent>
        </IonCard>
      </IonContent>
    </IonPage>
  );
};

export default StoryListPage;

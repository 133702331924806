import {
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonToolbar,
  IonProgressBar,
  IonButton,
  IonFooter,
  IonTitle,
} from "@ionic/react";
import "./HomePage.css";
import "./EventPage.css";
import { useTranslation } from "react-i18next";
import { RoundsContext } from "../logic/rounds";
import { AdventuresContext, getAdventureById } from "../logic/adventures";
import { EventsContext, getEventById } from "../logic/events";
import { getLanguageKey } from "../utils";
import { PortableText } from "@portabletext/react";
import { useHistory } from "react-router";
import { useContext } from "react";
import CurrentPlayer from "../components/CurrentPlayer";

const EventPage = () => {
  const history = useHistory();
  const { currentAdventureId } = useContext(AdventuresContext);
  const { currentRound } = useContext(RoundsContext);
  const { eventId } = useContext(EventsContext);

  const { t, i18n } = useTranslation();
  const language = getLanguageKey(i18n.language);
  const event = getEventById(eventId);

  // Return blank page if needed arguments are null
  if (!currentAdventureId || !eventId) return <IonPage></IonPage>;

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar className="dragon-skin-background">
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          {currentRound <
          getAdventureById(currentAdventureId).turnsNumber + 1 ? (
            <IonTitle>{getAdventureById(currentAdventureId).name}</IonTitle>
          ) : (
            <IonTitle>{t("epilogue")}</IonTitle>
          )}
          <div slot="end">
            <CurrentPlayer />
          </div>
          <IonProgressBar
            value={
              parseInt(currentRound) /
              getAdventureById(currentAdventureId).turnsNumber
            }
          ></IonProgressBar>
        </IonToolbar>
      </IonHeader>
      <IonContent className="body-background">
        <IonCard className="ion-padding small-content-with-background">
          {currentRound <
          getAdventureById(currentAdventureId).turnsNumber + 1 ? (
            <h1 className="ion-text-center">
              {t("year")} {currentRound}{" "}
            </h1>
          ) : (
            <h1 className="ion-text-center">{t("epilogue")}</h1>
          )}

          <IonCardContent>
            {Array.isArray(event.description?.it) ? (
              <PortableText value={event.description[language]} />
            ) : (
              <p> {event.description[language]}</p>
            )}
          </IonCardContent>
        </IonCard>
        {event.img && (
          <div className="small-content-with-background ion-justify-content-center ion-align-items-center event-image-content">
            <img
              alt={event.effect?.it}
              src={`/assets/img/events-images/${event?.img}`}
              className="event-image"
            />
          </div>
        )}
        <IonCard className="ion-padding small-content-with-background">
          <IonCardContent>
            {Array.isArray(event.effect?.it) ? (
              <div>
                <PortableText value={event.effect[language]} />
                {Array.isArray(event.recurringEffect?.it) && (
                  <PortableText value={event?.recurringEffect[language]} />
                )}
              </div>
            ) : (
              <div>
                <p> {event.effect[language]}</p>
                <p> {event?.recurringEffect[language]}</p>
              </div>
            )}
          </IonCardContent>
        </IonCard>
        <IonCard className="ion-padding small-content-with-background">
          {currentRound <
          getAdventureById(currentAdventureId).turnsNumber + 1 ? (
            <IonButton
              onClick={() => history.push("/changeTurn")}
              expand="full"
            >
              {t("next")}
            </IonButton>
          ) : (
            <IonButton onClick={() => history.push("/")} expand="full">
              {t("end-game")}
            </IonButton>
          )}
        </IonCard>
      </IonContent>
    </IonPage>
  );
};

export default EventPage;

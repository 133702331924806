import { IonLoading, setupIonicReact } from "@ionic/react";
import React, { Suspense, useState } from "react";

import { IonApp, IonRouterOutlet, IonSplitPane } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { Redirect, Route } from "react-router-dom";
import Menu from "./components/Menu";
import HomePage from "./pages/HomePage";
import SearchPage from "./pages/SearchPage";
import HistoryPage from "./pages/HistoryPage";
import LanguagePage from "./pages/LanguagePage";
import AdventurePage from "./pages/AdventurePage";
import StoryListPage from "./pages/StoryListPage";
import IntroPage from "./pages/IntroPage";
import EventPage from "./pages/EventPage";
import StoryPage from "./pages/StoryPage";
/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";

import "../node_modules/swiper/swiper.min.css";
import "@ionic/react/css/ionic-swiper.css";
import EpiloguePage from "./pages/EpiloguePage";
import StoryBookPage from "./pages/StoryBookPage";
import EventListPage from "./pages/EventListPage";
import GameConfigurePage from "./pages/GameConfiguration";
import ChangeTurnPage from "./pages/ChangeTurnPage";
import { AdventuresContextProvider } from "./logic/adventures";
import { RoundsContextProvider } from "./logic/rounds";
import { EventsContextProvider } from "./logic/events";
import { PlayersContextProvider } from "./logic/players";
import { StoriesContextProvider } from "./logic/stories";
import BoardConfigurePage from "./pages/BoardConfiguration";

setupIonicReact({
  mode: "md",
});
function App() {
  const [showLoading, setShowLoading] = useState(true);

  return (
    <IonApp>
      <Suspense
        fallback={
          <IonLoading
            cssClass="my-custom-class"
            isOpen={showLoading}
            onDidDismiss={() => setShowLoading(false)}
            message={"Please wait..."}
            duration={5000}
          />
        }
      >
        <AdventuresContextProvider>
          <RoundsContextProvider>
            <EventsContextProvider>
              <PlayersContextProvider>
                <StoriesContextProvider>
                  <IonReactRouter>
                    <IonSplitPane contentId="main">
                      <Menu />
                      <IonRouterOutlet id="main">
                        <Redirect exact from="/" to="/home" />
                        <Route path="/home">
                          <HomePage />
                        </Route>
                        <Route path="/select-adventure">
                          <AdventurePage />
                        </Route>
                        <Route path="/history">
                          <HistoryPage />
                        </Route>
                        <Route path="/search-story">
                          <SearchPage />
                        </Route>
                        <Route path="/language">
                          <LanguagePage />
                        </Route>
                        <Route path="/storyList">
                          <StoryListPage />
                        </Route>
                        <Route path="/intro">
                          <IntroPage />
                        </Route>
                        <Route path="/epilogue">
                          <EpiloguePage />
                        </Route>
                        <Route path="/story/:storyId">
                          <StoryPage />
                        </Route>
                        <Route path="/eventPage">
                          <EventPage />
                        </Route>
                        <Route path="/storyBook">
                          <StoryBookPage />
                        </Route>
                        <Route path="/eventList">
                          <EventListPage />
                        </Route>
                        <Route path="/gameConfiguration">
                          <GameConfigurePage />
                        </Route>
                        <Route path="/boardConfiguration">
                          <BoardConfigurePage />
                        </Route>
                        <Route path="/changeTurn">
                          <ChangeTurnPage />
                        </Route>
                      </IonRouterOutlet>
                    </IonSplitPane>
                  </IonReactRouter>
                </StoriesContextProvider>
              </PlayersContextProvider>
            </EventsContextProvider>
          </RoundsContextProvider>
        </AdventuresContextProvider>
      </Suspense>
    </IonApp>
  );
}

export default App;

import { IonButton, IonCard } from "@ionic/react";
import { PortableText } from "@portabletext/react";
import Cost from "./Cost";
import Result from "./Result";
import { useTranslation } from "react-i18next";
import "./Story.css";
import StoryAttribute from "./StoryAttribute";
import { getAlignmentById, getElementById } from "../logic/attributes";
import { getLanguageKey } from "../utils";
import Encounter from "./Encounter";
import { getStoryById, StoriesContext } from "../logic/stories";
import {
  getVariableById,
  variableBelongsToAdventure,
} from "../logic/variables";
import { AdventuresContext } from "../logic/adventures";
import { useHistory } from "react-router";
import { useContext } from "react";

const Story = ({ story }) => {
  const history = useHistory();

  const { currentAdventureId } = useContext(AdventuresContext);
  const { currentStoryId, addToHistory } = useContext(StoriesContext);

  const {
    type,
    title,
    text,
    choices,
    costs,
    results,
    alternativeCosts,
    alternativeResults,
    tileId,
  } = story;

  const { t, i18n } = useTranslation();

  const language = getLanguageKey(i18n.language);

  return (
    <div>
      {story.type && (
        <div>
          <IonCard className="border-element small-content-with-background">
            <div>
              {" "}
              {type !== "result" && tileId !== 1 && tileId < 1000 && (
                <StoryAttribute story={story} />
              )}
              <h2>{title && title[language]}</h2>
              {story?.audio && (
                <audio id="audio" controls controlsList="nodownload">
                  <source
                    src={`assets/audio/stories/${story?.audio}`}
                    type="audio/mpeg"
                  />
                  Your browser does not support the audio tag.
                </audio>
              )}
            </div>
            {type !== "result" && (
              <div className="ion-margin-bottom">
                {Array.isArray(text?.it) ? (
                  <PortableText value={text[language]} />
                ) : (
                  <p>{text[language]}</p>
                )}
              </div>
            )}
            {choices &&
              choices.map((choice, index) => (
                <IonButton
                  className="ion-text-wrap ion-margin-bottom choice-button dragon-skin-background"
                  color={
                    choice.requiredAlignment &&
                    (getAlignmentById(choice.requiredAlignment._ref).name ===
                    "negative"
                      ? "dark"
                      : "light")
                  }
                  expand="full"
                  key={index}
                  onClick={() => {
                    addToHistory(choice.goto._ref);
                    history.push(`/story/${choice.goto._ref}`);
                  }}
                >
                  <span>
                    <p className="choice">{choice.question[language]}</p>

                    {getStoryById(choice.goto._ref).costs &&
                      getStoryById(choice.goto._ref).costs.map(
                        (cost, index) => (
                          <span key={index}>
                            {cost &&
                              variableBelongsToAdventure(
                                currentAdventureId,
                                cost
                              ) && (
                                <img
                                  className="icon-cost"
                                  alt={
                                    getVariableById(cost.variable._ref).label[
                                      language
                                    ]
                                  }
                                  src={`assets/icon/${
                                    getVariableById(cost.variable._ref).img
                                  }`}
                                />
                              )}
                          </span>
                        )
                      )}
                    {getStoryById(choice.goto._ref).type ===
                      "alignment-choice" && (
                      <span>
                        <img
                          className="icon-cost"
                          alt="alignment"
                          src={`assets/icon/SVG/Negative_Karma.svg`}
                        />
                        <img
                          className="icon-cost"
                          alt="alignment"
                          src={`assets/icon/SVG/Positive_Karma.svg`}
                        />
                      </span>
                    )}
                  </span>
                </IonButton>
              ))}
          </IonCard>
          {type === "result" && (
            <IonCard className="ion-padding small-content-with-background">
              {costs && <Cost costs={costs} />}
              {results && <Result results={results} />}
            </IonCard>
          )}
          {alternativeCosts && (
            <IonCard color="medium" className="ion-padding small-content">
              <h3>{t("alternative-resolution")}</h3>
              {alternativeCosts && (
                <Cost color="medium" costs={alternativeCosts} />
              )}
              {alternativeResults && (
                <Result color="medium" results={alternativeResults} />
              )}
            </IonCard>
          )}
          {type === "result" && (
            <div>
              <IonCard className="ion-padding small-content-with-background">
                <p>
                  Se non puoi soddisfare i requisiti di questa Storia, puoi
                  sempre scegliere di combattere gli abitanti di questo luogo
                </p>
                <Encounter />
              </IonCard>
              <IonCard className="ion-padding small-content-with-background">
                <div className="ion-margin-bottom">
                  {Array.isArray(text?.it) ? (
                    <PortableText value={text[language]} />
                  ) : (
                    <p>{text[language]}</p>
                  )}
                </div>
              </IonCard>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Story;

import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useContext } from "react";

import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import Story from "../components/Story";
import { AdventuresContext, getAdventureById } from "../logic/adventures";
import { audioPlayer, getStoryById, StoriesContext } from "../logic/stories";

const IntroPage = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { currentAdventureId } = useContext(AdventuresContext);
  const { currentStoryId } = useContext(StoriesContext);

  const intro = getStoryById(currentStoryId);
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar className="dragon-skin-background">
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>
            {getAdventureById(currentAdventureId) &&
              getAdventureById(currentAdventureId)?.name}
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="body-background">
        <Story story={intro} />
      </IonContent>
      <IonFooter className="ion-padding ion-text-center dragon-skin-background">
        <div className="small-content-no-margin-top ion-text-center">
          <IonButton
            expand="full"
            onClick={() => {
              history.push("/eventPage");
              audioPlayer("stop");
            }}
            color="primary"
          >
            {t("next")}
          </IonButton>
        </div>
      </IonFooter>
    </IonPage>
  );
};

export default IntroPage;

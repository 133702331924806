import {
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonGrid,
} from "@ionic/react";
import "./HomePage.css";
import { useTranslation } from "react-i18next";
import AdventureList from "../components/AdventureList";

const AdventurePage = () => {
  const { t } = useTranslation();

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar className="dragon-skin-background">
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Age of Dragons</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="body-background">
        <IonCard className="large-content-with-background">
          <h2 className="ion-text-center">{t("adventure-selection")}</h2>

          <IonCardContent>
            <AdventureList />
          </IonCardContent>
        </IonCard>
      </IonContent>
    </IonPage>
  );
};

export default AdventurePage;

import { IonRow, IonCol, IonButton, IonIcon } from "@ionic/react";

import { getLanguageKey } from "../utils";

import { useTranslation } from "react-i18next";
import {
  getAllStoryResultsByAdventureId,
  getStoryResultsByStoryRoot,
  getAllStoriesByAdventureId,
} from "../logic/stories";
import {
  getAlignmentById,
  getElementById,
  getLevelById,
} from "../logic/attributes";
import {
  getVariableById,
  variableBelongsToAdventure,
} from "../logic/variables";

import { PortableText } from "@portabletext/react";
import { exportResultsToExcell } from "../utils";
import { downloadOutline } from "ionicons/icons";

const StoryList = (props) => {
  const { i18n } = useTranslation();

  const language = getLanguageKey(i18n.language);
  const stories = getAllStoriesByAdventureId(props.adventureId);
  const storyResults = getAllStoryResultsByAdventureId(props.adventureId);

  return (
    <div>
      <IonButton
        color="primary"
        onClick={() => exportResultsToExcell(storyResults)}
      >
        <IonIcon slot="start" icon={downloadOutline}></IonIcon>
        Export
      </IonButton>
      <IonRow>
        <IonCol>
          <strong>Tile ID</strong>
        </IonCol>
        <IonCol>
          <strong>Title</strong>
        </IonCol>
        <IonCol>
          <strong>Level</strong>
        </IonCol>
        <IonCol>
          <strong>Element</strong>
        </IonCol>
        <IonCol>
          <strong>Alignment</strong>
        </IonCol>
        <IonCol>
          <strong>Costs</strong>
        </IonCol>
        <IonCol>
          <strong>Results</strong>
        </IonCol>
      </IonRow>
      {stories.map((story, index) => (
        <IonRow key={index} className="tableRow">
          <IonCol>{story.tileId}</IonCol>
          <IonCol>{story.title[language]}</IonCol>
          <IonCol>{getLevelById(story?.level?._ref)?.level}</IonCol>
          <IonCol>{getElementById(story?.primaryElement?._ref)?.name}</IonCol>
          <IonCol>
            {getAlignmentById(story?.alignment?._ref)?.label[language]}
          </IonCol>
          <IonCol>
            {getStoryResultsByStoryRoot(story._id).map(
              (storyResult, indexB) => (
                <span key={indexB}>
                  {storyResult.costs &&
                    storyResult.costs.map((cost, indexC) => (
                      <span key={indexC}>
                        {cost &&
                          variableBelongsToAdventure(
                            props.adventureId,
                            cost
                          ) && (
                            <span>
                              <img
                                className="icon-mini"
                                alt={
                                  getVariableById(cost?.variable?._ref)?.label[
                                    language
                                  ]
                                }
                                title={cost?.label[language]}
                                src={`assets/icon/${
                                  getVariableById(cost?.variable?._ref)?.img
                                }`}
                              />
                            </span>
                          )}
                      </span>
                    ))}
                  {"|"}
                </span>
              )
            )}
          </IonCol>
          <IonCol>
            {" "}
            {getStoryResultsByStoryRoot(story._id).map(
              (storyResult, indexB) => (
                <span key={indexB}>
                  {storyResult.results &&
                    storyResult.results.map((result, indexC) => (
                      <span key={indexC}>
                        {result &&
                          variableBelongsToAdventure(
                            props.adventureId,
                            result
                          ) && (
                            <span>
                              <img
                                className="icon-mini"
                                alt={
                                  getVariableById(result?.variable?._ref)
                                    ?.label[language]
                                }
                                title={result?.label[language]}
                                src={`assets/icon/${
                                  getVariableById(result?.variable?._ref)?.img
                                }`}
                              />
                            </span>
                          )}
                      </span>
                    ))}
                  {"|"}
                </span>
              )
            )}
          </IonCol>
        </IonRow>
      ))}
      <strong>Total: {stories.length}</strong> <br></br>
    </div>
  );
};

export default StoryList;
